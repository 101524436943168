import React, { useState } from "react";
import useWindowWidth from "../Hooks/useWindowWidth";
import { BsHourglassSplit } from "react-icons/bs";

const Challenge = () => {
  const width = useWindowWidth();

  return (
    <div className="challenge-container" id="challenge-sec">
      <h1 className="challenge-header">THE COST OF INACTION</h1>
      <div className="challenge-card-container">
        <div id="challenge-div">
          <div className="card-stripe"></div>
          <div className="card-content">
            <div className="challenge-title-cont">
              <div className="png-container-ch">
                <div className="limits-png"></div>
              </div>
              <div className="challenge-card-title">Scalability Limitations and Resource Constraints</div>
            </div>

            <p>
              Traditional methods struggle to keep pace with the demands of a growing market. As businesses scale, manual and repetitive processes become bottlenecks and less efficient, consuming
              valuable time and resources that could be better utilized.
            </p>
          </div>
        </div>

        <div id="challenge-div">
          <div className="card-stripe"></div>
          <div className="card-content">
            <div className="challenge-title-cont">
              <div className="png-container-ch">
                <div className="hourglass-png"></div>
              </div>
              <div className="challenge-card-title">Inadequate Market Responsiveness and Missed Opportunities</div>
            </div>
            <p>
              Without predictive insights and real-time data analysis, businesses are unprepared for market shifts, leading to missed opportunities, reduced competitiveness, and delayed
              decision-making; leading to costly mistakes.
            </p>
          </div>
        </div>
        <div id="challenge-div">
          <div className="card-stripe"></div>
          <div className="card-content">
            <div className="challenge-title-cont">
              <div className="png-container-ch">
                <div className="burn-png"></div>
              </div>
              <div className="challenge-card-title">Poor Employee and Customer Experiences</div>
            </div>
            <p>
              Manual tasks lead to employee burnout, productivity, and increased turnover rates. Additionally, a one-size-fits-all approach to customer interactions fails to provide the personalized
              experiences that modern customers expect, resulting in inconsistent service quality.
            </p>
          </div>
        </div>
        {/* <div className="blob"></div> */}
      </div>
    </div>
  );
};

export default Challenge;
