import { Link as LINK } from "react-scroll";
import { Link } from "react-router-dom";

import openURL from "../Hooks/openURL";
import React, { useEffect, useState } from "react";
import useScroll from "../Hooks/useScroll";
import cn from "classnames";
import useWindowWidth from "../Hooks/useWindowWidth";

const Navbar = () => {
  let position = useScroll();
  const width = useWindowWidth();

  useEffect(() => {}, [position]);

  const handleLogoClick = () => {
    window.scrollTo(0, 0);
  };
  return (
    <div className={cn("navbar-container", { has_scrolled: width >= 800 ? position !== 0 : false })}>
      <Link to="/" className="navbar-left" onClick={handleLogoClick}>
        <div
          className={cn("navbar-title", {
            title_scrolled: position !== 0,
          })}
        ></div>
        <h1
          className={cn("nav-brand", {
            scrolled_nav: position !== 0,
          })}
        >
          OMNI NEXUS{" "}
        </h1>
      </Link>
      <div className={cn("navbar-links-container")}>
        <LINK className={cn("nav-alink", { nav_links_scroll: position !== 0 })} to="solutions-sec" offset={-150} smooth={true}>
          SOLUTIONS{" "}
        </LINK>

        <LINK className={cn("nav-alink", { nav_links_scroll: position !== 0 })} to="about-sec" smooth={true} offset={-150}>
          ABOUT{" "}
        </LINK>

        <LINK className={cn("nav-alink", { nav_links_scroll: position !== 0 })} to="contact-sec" offset={-150} smooth={true}>
          CONTACT{" "}
        </LINK>
      </div>
      <div className="navbar-right">
        <div className="navbar-consult-container">
          <button className={cn("demo-button dark-nav-btn", { dark_nav_btn: position !== 0 })} onClick={openURL}>
            Schedule a Call
          </button>
        </div>
        <div></div>
      </div>
    </div>
  );
};
export default Navbar;
