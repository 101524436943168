const tabsContent = {
  "GPT | Llama | Chatbot Integration": [
    "With the power of modern LLMs, we produce natural, human-like text for relatable interactions. Leveraging Natural Language Processing (NLP), with long term memory, we are able to deliver custom AI powered Chatbot solutions that can query files, pdf's, website information and more. Implementing Knowledge Response we deliver immediate, accurate answers, improving customer queries and user engagement. Through Model Tuning, we tailor AI tools to your business needs, ensuring top performance and results.",
  ],
  "Software Automation": [
    "Seamlessly connect over 1000+ apps, streamlining operations, and breaking down silos. Our expertise ensures Tech Stack Synchronization, creating a unified and efficient ecosystem. Through Workflow Optimization, we refine processes to enhance productivity and reduce manual efforts. Additionally, our Real-time Data Integration ensures that your business decisions are informed by the latest data, in an instant, making agility a standard practice.",
  ],
  "AI Consulting": [
    "We evaluate the potential of AI integrations tailored for your specific needs. Our Use Case Identification, pinpoints the perfect scenarios to harness the power of AI, ensuring maximum ROI. Through Performance Evaluation, we continuously monitor and refine these integrations, guaranteeing they not only meet but exceed benchmarks. Strategy Development becomes a collaborative journey, crafting a comprehensive AI roadmap for your business's bright future.",
  ],
  // "Natural Language Processing (NLP)": [
  //     "Delve into the world of text and data with Omni Nexus's Natural Language Processing (NLP) services. Our Document Categorization streamlines vast information repositories, ensuring swift and efficient access to critical data. With Data Mining, we unearth hidden insights from your textual content, paving the way for informed decision-making. Our Text Summarization tools distill large volumes of text into concise and meaningful summaries, saving time and enhancing comprehension. And through Entity Identification, we detect and classify crucial data points, making data extraction a breeze and ensuring that no vital information slips through the cracks.",
  // ],
};
export default tabsContent;
