import tabsContent from "./Tabs";
import React, { useState } from "react";
import useWindowWidth from "../Hooks/useWindowWidth";
import cn from "classnames";

const Solutions = () => {
  const width = useWindowWidth();
  return (
    <div className="solutions-container" name="solutions-sec">
      <h1 className="solutions-header">SOLUTIONS</h1>
      <div id="solutions-services-container">
        {Object.entries(tabsContent)?.map((tab, i) => (
          <div className="solutions-swiper-container" key={i}>
            {width >= 800 ? (
              i % 2 === 0 ? ( // Check if the index is even
                // Image on left, content on right for even indices
                <div className={cn("odd-layout-container")}>
                  <div className="solution-image-container">
                    <div className={`solution-image-${i}`} />
                  </div>
                  <div className="solution-text-container">
                    <div className="solution-title">{tab[0]}</div>
                    <div className="solution-body">{tab[1]}</div>
                  </div>
                </div>
              ) : (
                // Image on right, content on left for odd indices
                <div className={cn("even-layout-container", {})}>
                  <div className="solution-text-container">
                    <div className="solution-title">{tab[0]}</div>
                    <div className="solution-body">{tab[1]}</div>
                  </div>
                  <div className="solution-image-container">
                    <div className={`solution-image-${i}`} />
                  </div>
                </div>
              )
            ) : (
              // For smaller screens, always show content below the image
              <div className="solutions-container-full-width">
                <div className={`solutions-full-width-image full-width-${i}`} />
                <div className="solutions-swiper-full-width-text">
                  <div className="solutions-mb-text-title">{tab[0]}</div>
                  <div className="landing-offerings-mb-body">{tab[1]}</div>
                </div>
              </div>
            )}
          </div>
        ))}
      </div>
    </div>
  );
};

export default Solutions;
