import React from "react";
import { BrowserRouter, Route, Routes } from "react-router-dom";
import "./styles/css/index.css";
import Navbar from "./components/Navbar";
import Landing from "./components/Landing";
import Challenge from "./components/Challenge";
import Solutions from "./components/Solutions";
import Contact from "./components/Contact";
import About from "./components/About";
import Privacy from "./components/Contact/Privacy";
import NodesAnimation from "./components/Landing/NodesAnimation";

const App = () => {
  return (
    <BrowserRouter>
      <Navbar />
      <Routes>
        <Route
          path="/"
          element={
            <>
              <div className="nav-land-cont">
                <Landing />
              </div>
              <Solutions />
              <About />
              <Challenge />
              <Contact />
            </>
          }
        />
        <Route path="/privacy" element={<Privacy />} />
      </Routes>
    </BrowserRouter>
  );
};

export default App;
