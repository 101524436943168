import { Link } from "react-router-dom";
import _Modal from "../Hooks/Modal";
import React from "react";
import Privacy from "./Privacy";
// import privacy from "./privacy.html";

const Contact = () => {
  return (
    <div className="contact-container" id="contact-sec">
      <div className="contact-info-container">
        <h1 className="contact-header">CONTACT US</h1>
        <div className="contact-top">
          <div className="contact-right">
            <p className="contact-email">CONTACT</p>
            <p>contact@omninexus.co</p>
          </div>
          <div className="contact-left">
            <p className="contact-contents-header">CONTENTS</p>
            <div className="contact-links">
              <Link>About</Link>
              <Link>Solutions</Link>
              {/* <Link> */}
              <a href="https://oj8qcpn67dj.typeform.com/to/NR0ggFj7" target="_blank" rel="noreferrer noopener">
                Schedule a Call
              </a>
              {/* </Link> */}
            </div>
          </div>
        </div>

        <div className="contact-divider"></div>
        <div className="contact-bottom">
          <div className="contact-socials">
            <a href="https://www.linkedin.com/company/omni-nexus-llc" target="_blank" rel="noreferrer noopener">
              <svg className="linkedin-svg" />
            </a>
          </div>
          <div className="contact-rights">
            <p className="contact-all-rights">
              All Rights Reserved © 2023
              <br />
              <Link className="privacy-link-contact" to="privacy">
                Privacy Policy
              </Link>
              <br />
              Omni Nexus LLC.
            </p>
          </div>
        </div>
      </div>
    </div>
  );
};
export default Contact;
