const Privacy = () => {
  return (
    <div className="privacy-container">
      <h3 className="pr-title">Privacy Policy</h3>
      <p className="pr-date">Effective Date 10/10/2023</p>
      <p>
        This privacy notice for Omni Nexus, LLC (<strong>"we"</strong>,<strong>"us"</strong>, or <strong>"our"</strong>), describes how and why we might collect, store, use, and/or share (
        <strong>"process"</strong>) your information when you use our services (<strong>"Services"</strong>), such as when you:
      </p>
      <ul>
        <li>Visit our website at http://www.omninexus.co , or any website of ours that links to this privacy notice</li>
        <li>Engage with us in other related ways, including any sales, marketing, or events</li>
      </ul>
      <strong>Questions or concerns?</strong>
      <p>
        Reading this privacy notice will help you understand your privacy rights and choices. If you do not agree with our policies and practices, please do not use our Services. If you still have any
        questions or concerns, please contact us at contact@omninexus.co.
      </p>
      <h3>Summary of Key Points</h3>
      <strong>
        This summary provides key points from our privacy notice, but you can find out more details about any of these topics by clicking the link following each key point or by using our table of
        contents below to find the section you are looking for.
      </strong>
      <strong>What personal information do we process?</strong>
      <p>
        When you visit, use, or navigate our Services, we may process personal information depending on how you interact with us and the Services, the choices you make, and the products and features
        you use. Learn more about personal information you disclose to us.
      </p>
      <p>
        <strong>Do we process any sensitive personal information?</strong>
        We do not process sensitive personal information.
      </p>
      <p>
        <strong>Do we receive any information from third parties?</strong>
        We do not receive any information from third parties.
      </p>
      <p>
        How do we process your information? We process your information to provide, improve, and administer our Services, communicate with you, for security and fraud prevention, and to comply with
        law. We may also process your information for other purposes with your consent. We process your information only when we have a valid legal reason to do so. Learn more about how we process
        your information.
      </p>
      <p>
        In what situations and with which parties do we share personal information? We may share information in specific situations and with specific third parties. Learn more about when and with whom
        we share your personal information.
      </p>
      <p>
        How do we keep your information safe? We have organizational and technical processes and procedures in place to protect your personal information. However, no electronic transmission over the
        internet or information storage technology can be guaranteed to be 100% secure, so we cannot promise or guarantee that hackers, cybercriminals, or other unauthorized third parties will not be
        able to defeat our security and improperly collect, access, steal, or modify your information. Learn more about how we keep your information safe.
      </p>
      <p>
        What are your rights? Depending on where you are located geographically, the applicable privacy law may mean you have certain rights regarding your personal information. Learn more about your
        privacy rights .
      </p>
      <p>
        <strong>How do you exercise your rights?</strong>
        The easiest way to exercise your rights is by submitting a data subject access request , or by contacting us. We will consider and act upon any request in accordance with applicable data
        protection laws.
      </p>
      <p>
        Want to learn more about what we do with any information we collect?
        <a href="#">Review the privacy notice in full.</a>
      </p>
      <h3>Table of contents</h3>
      <a>1. WHAT INFORMATION DO WE COLLECT?</a>
      <a>2. HOW DO WE PROCESS YOUR INFORMATION?</a>
      <a>3. WHEN AND WITH WHOM DO WE SHARE YOUR PERSONAL INFORMATION?</a>
      <a>4. HOW LONG DO WE KEEP YOUR INFORMATION?</a>
      <a>5. HOW DO WE KEEP YOUR INFORMATION SAFE?</a>
      <a>6. DO WE COLLECT INFORMATION FROM MINORS?</a>
      <a>7. WHAT ARE YOUR PRIVACY RIGHTS?</a>
      <a>8. CONTROLS FOR DO-NOT-TRACK FEATURES</a>
      <a>9. DO CALIFORNIA RESIDENTS HAVE SPECIFIC PRIVACY RIGHTS?</a>
      <a>10. DO VIRGINIA RESIDENTS HAVE SPECIFIC PRIVACY RIGHTS?</a>
      <a>11. DO WE MAKE UPDATES TO THIS NOTICE?</a>
      <a>12. HOW CAN YOU CONTACT US ABOUT THIS NOTICE?</a>
      <a>13. HOW CAN YOU REVIEW, UPDATE, OR DELETE THE DATA WE COLLECT FROM YOU?</a>
      <h3>1. WHAT INFORMATION DO WE COLLECT?</h3>
      <p>Personal information you disclose to us</p>
      <p>
        <strong>In Short:</strong> We collect personal information that you provide to us.
      </p>
      <p>
        We collect personal information that you voluntarily provide to us when you express an interest in obtaining information about us or our products and Services, when you participate in
        activities on the Services, or otherwise when you contact us.
      </p>
      <p>
        <strong>Personal Information Provided by You.</strong>
        The personal information that we collect depends on the context of your interactions with us and the Services, the choices you make, and the products and features you use. The personal
        information we collect may include the following:
      </p>
      <ul>
        <li>email addresses</li>
        <li>phone numbers</li>
        <li>names</li>
        <li>contact preferences</li>
      </ul>
      <p>
        <strong>Sensitive Information.</strong>
        We do not process sensitive information.
      </p>
      <p>All personal information that you provide to us must be true, complete, and accurate, and you must notify us of any changes to such personal information.</p>
      <h3>Information automatically collected</h3>
      <p>
        <strong>In Short:</strong>
        Some information — such as your Internet Protocol (IP) address and/or browser and device characteristics — is collected automatically when you visit our Services.
      </p>
      <p>
        We automatically collect certain information when you visit, use, or navigate the Services. This information does not reveal your specific identity (like your name or contact information) but
        may include device and usage information, such as your IP address, browser and device characteristics, operating system, language preferences, referring URLs, device name, country, location,
        information about how and when you use our Services, and other technical information. This information is primarily needed to maintain the security and operation of our Services, and for our
        internal analytics and reporting purposes. The information we collect includes:
        <ul>
          <li>
            Device Data. We collect device data such as information about your computer, phone, tablet, or other device you use to access the Services. Depending on the device used, this device data
            may include information such as your IP address (or proxy server), device and application identification numbers, location, browser type, hardware model, Internet service provider and/or
            mobile carrier, operating system, and system configuration information.
          </li>
          <li>
            Location Data. We collect location data such as information about your device's location, which can be either precise or imprecise. How much information we collect depends on the type and
            settings of the device you use to access the Services. For example, we may use GPS and other technologies to collect geolocation data that tells us your current location (based on your IP
            address). You can opt out of allowing us to collect this information either by refusing access to the information or by disabling your Location setting on your device. However, if you
            choose to opt out, you may not be able to use certain aspects of the Services.
          </li>
        </ul>
      </p>
      <h3>2. HOW DO WE PROCESS YOUR INFORMATION?</h3>
      <p>
        <strong>In Short:</strong>
      </p>
      <p>
        We process your information to provide, improve, and administer our Services, communicate with you, for security and fraud prevention, and to comply with law. We may also process your
        information for other purposes with your consent. We process your personal information for a variety of reasons, depending on how you interact with our Services, including:
        <ul>
          <li>To deliver and facilitate delivery of services to the user. We may process your information to provide you with the requested service.</li>
          <li>
            To respond to user inquiries/offer support to users. We may process your information to respond to your inquiries and solve any potential issues you might have with the requested service.
          </li>
          <li>To request feedback. We may process your information when necessary to request feedback and to contact you about your use of our Services.</li>
          <li>
            To send you marketing and promotional communications. We may process the personal information you send to us for our marketing purposes, if this is in accordance with your marketing
            preferences. You can opt out of our marketing emails at any time. For more information, see " WHAT ARE YOUR PRIVACY RIGHTS? " below.
          </li>
          <li>To deliver targeted advertising to you. We may process your information to develop and display personalized content and advertising tailored to your interests, location, and more.</li>
          <li>To post testimonials. We post testimonials on our Services that may contain personal information.</li>
          <li>To protect our Services. We may process your information as part of our efforts to keep our Services safe and secure, including fraud monitoring and prevention.</li>
          <li>
            To evaluate and improve our Services, products, marketing, and your experience. We may process your information when we believe it is necessary to identify usage trends, determine the
            effectiveness of our promotional campaigns, and to evaluate and improve our Services, products, marketing, and your experience.
          </li>
          <li>To identify usage trends. We may process information about how you use our Services to better understand how they are being used so we can improve them.</li>
          <li>
            To determine the effectiveness of our marketing and promotional campaigns. We may process your information to better understand how to provide marketing and promotional campaigns that are
            most relevant to you.
          </li>
          <li>
            To comply with our legal obligations. We may process your information to comply with our legal obligations, respond to legal requests, and exercise, establish, or defend our legal rights.
          </li>
        </ul>
      </p>
      <h3>3. WHEN AND WITH WHOM DO WE SHARE YOUR PERSONAL INFORMATION?</h3>
      <p>
        <strong>In Short:</strong>
        We may share information in specific situations described in this section and/or with the following third parties.
      </p>
      <p>
        We may need to share your personal information in the following situations:
        <ul>
          <li>
            Business Transfers. We may share or transfer your information in connection with, or during negotiations of, any merger, sale of company assets, financing, or acquisition of all or a
            portion of our business to another company.
          </li>
          <li>
            Affiliates. We may share your information with our affiliates, in which case we will require those affiliates to honor this privacy notice. Affiliates include our parent company and any
            subsidiaries, joint venture partners, or other companies that we control or that are under common control with us.
          </li>
          <li>Business Partners. We may share your information with our business partners to offer you certain products, services, or promotions.</li>
        </ul>
      </p>
      <h3>4. HOW LONG DO WE KEEP YOUR INFORMATION?</h3>
      <p>
        <strong>In Short:</strong>
      </p>
      <p>
        We keep your information for as long as necessary to fulfill the purposes outlined in this privacy notice unless otherwise required by law. We will only keep your personal information for as
        long as it is necessary for the purposes set out in this privacy notice, unless a longer retention period is required or permitted by law (such as tax, accounting, or other legal
        requirements).
      </p>
      <p>
        When we have no ongoing legitimate business need to process your personal information, we will either delete or anonymize such information, or, if this is not possible (for example, because
        your personal information has been stored in backup archives), then we will securely store your personal information and isolate it from any further processing until deletion is possible.
      </p>
      <h3>5. HOW DO WE KEEP YOUR INFORMATION SAFE?</h3>
      <p>
        <strong>In Short:</strong>
        We aim to protect your personal information through a system of organizational and technical security measures.
      </p>
      <p>
        We have implemented appropriate and reasonable technical and organizational security measures designed to protect the security of any personal information we process. However, despite our
        safeguards and efforts to secure your information, no electronic transmission over the Internet or information storage technology can be guaranteed to be 100% secure, so we cannot promise or
        guarantee that hackers, cybercriminals, or other unauthorized third parties will not be able to defeat our security and improperly collect, access, steal, or modify your information. Although
        we will do our best to protect your personal information, transmission of personal information to and from our Services is at your own risk. You should only access the Services within a secure
        environment.
      </p>
      <h3>6. DO WE COLLECT INFORMATION FROM MINORS?</h3>
      <p>
        <strong>In Short: We do not knowingly collect data from or market to children under 18 years of age.</strong>
        We do not knowingly solicit data from or market to children under 18 years of age. By using the Services, you represent that you are at least 18 or that you are the parent or guardian of such
        a minor and consent to such minor dependent’s use of the Services. If we learn that personal information from users less than 18 years of age has been collected, we will deactivate the account
        and take reasonable measures to promptly delete such data from our records. If you become aware of any data we may have collected from children under age 18, please contact us at
        support@omninexus.co.
      </p>
      <h3>7. WHAT ARE YOUR PRIVACY RIGHTS?</h3>
      <p>
        <strong>In Short:</strong>
      </p>
      <p>
        You may review, change, or terminate your account at any time. Withdrawing your consent: If we are relying on your consent to process your personal information, which may be express and/or
        implied consent depending on the applicable law, you have the right to withdraw your consent at any time. You can withdraw your consent at any time by contacting us by using the contact
        details provided in the section " HOW CAN YOU CONTACT US ABOUT THIS NOTICE?" below.
      </p>
      <p>
        However, please note that this will not affect the lawfulness of the processing before its withdrawal nor, when applicable law allows, will it affect the processing of your personal
        information conducted in reliance on lawful processing grounds other than consent.
      </p>
      <p>
        Opting out of marketing and promotional communications: You can unsubscribe from our marketing and promotional communications at any time by clicking on the unsubscribe link in the emails that
        we send, or by contacting us using the details provided in the section " HOW CAN YOU CONTACT US ABOUT THIS NOTICE? " below. You will then be removed from the marketing lists. However, we may
        still communicate with you — for example, to send you service-related messages that are necessary for the administration and use of your account, to respond to service requests, or for other
        non-marketing purposes.
      </p>
      <h3></h3>
      8. CONTROLS FOR DO-NOT-TRACK FEATURES
      <p>
        Most web browsers and some mobile operating systems and mobile applications include a Do-Not-Track ( "DNT" ) feature or setting you can activate to signal your privacy preference not to have
        data about your online browsing activities monitored and collected. At this stage no uniform technology standard for recognizing and implementing DNT signals has been finalized . As such, we
        do not currently respond to DNT browser signals or any other mechanism that automatically communicates your choice not to be tracked online. If a standard for online tracking is adopted that
        we must follow in the future, we will inform you about that practice in a revised version of this privacy notice.
      </p>
      <h3>9. DO CALIFORNIA RESIDENTS HAVE SPECIFIC PRIVACY RIGHTS?</h3>
      <p>
        <strong>In Short:</strong>
        Yes, if you are a resident of California, you are granted specific rights regarding access to your personal information.
      </p>
      <p>
        California Civil Code Section 1798.83, also known as the "Shine The Light" law, permits our users who are California residents to request and obtain from us, once a year and free of charge,
        information about categories of personal information (if any) we disclosed to third parties for direct marketing purposes and the names and addresses of all third parties with which we shared
        personal information in the immediately preceding calendar year. If you are a California resident and would like to make such a request, please submit your request in writing to us using the
        contact information provided below.
      </p>
      <p>
        If you are under 18 years of age, reside in California, and have a registered account with Services, you have the right to request removal of unwanted data that you publicly post on the
        Services. To request removal of such data, please contact us using the contact information provided below and include the email address associated with your account and a statement that you
        reside in California. We will make sure the data is not publicly displayed on the Services, but please be aware that the data may not be completely or comprehensively removed from all our
        systems (e.g. , backups, etc.).
      </p>
      <h3>CCPA Privacy Notice</h3>
      <p>
        The California Code of Regulations defines a "resident" as: (1) every individual who is in the State of California for other than a temporary or transitory purpose and (2) every individual who
        is domiciled in the State of California who is outside the State of California for a temporary or transitory purpose All other individuals are defined as "non-residents." If this definition of
        "resident" applies to you, we must adhere to certain rights and obligations regarding your personal information. What categories of personal information do we collect? We have collected the
        following categories of personal information in the past twelve (12) months:
      </p>
      <table>
        <tr>
          <td>Category</td>
          <td>Examples</td>
          <td>Collected</td>
        </tr>
        <tr>
          <td>A. Identifiers</td>
          <td>
            Contact details, such as real name, alias, postal address, telephone or mobile contact number, unique personal identifier, online identifier, Internet Protocol address, email address, and
            account name
          </td>
          <td>NO</td>
        </tr>
        <tr>
          <td>B. Personal information categories listed in the California Customer Records statute</td>
          <td>Name, contact information, education, employment, employment history, and financial information</td>
          <td>NO</td>
        </tr>
        <tr>
          <td>C. Protected classification characteristics under California or federal law</td>
          <td>Gender and date of birth</td>
          <td>NO</td>
        </tr>
        <tr>
          <td>D. Commercial information</td>
          <td>Transaction information, purchase history, financial details, and payment information</td>
          <td>NO</td>
        </tr>
        <tr>
          <td>E. Biometric information</td>
          <td>Fingerprints and voiceprints</td>
          <td>NO</td>
        </tr>
        <tr>
          <td>F. Internet or other similar network activity</td>
          <td>Browsing history, search history, online behavior , interest data, and interactions with our and other websites, applications, systems, and advertisements</td>
          <td>NO</td>
        </tr>
        <tr>
          <td>G. Geolocation data</td>
          <td>Device location</td>
          <td>NO</td>
        </tr>
        <tr>
          <td>H. Audio, electronic, visual, thermal, olfactory, or similar information</td>
          <td>Images and audio, video or call recordings created in connection with our business activities</td>
          <td>NO</td>
        </tr>
        <tr>
          <td>I. Professional or employment-related information</td>
          <td>Business contact details in order to provide you our Services at a business level or job title, work history, and professional qualifications if you apply for a job with us</td>
          <td>NO</td>
        </tr>
        <tr>
          <td>J. Education Information</td>
          <td>Student records and directory information</td>
          <td>NO</td>
        </tr>
        <tr>
          <td>K. Inferences drawn from other personal information</td>
          <td>Inferences drawn from any of the collected personal information listed above to create a profile or summary about, for example, an individual’s preferences and characteristics</td>
          <td>NO</td>
        </tr>
        <tr>
          <td>L. Sensitive Personal Information</td>
          <td></td>
          <td>NO</td>
        </tr>
      </table>
      <p>
        We may also collect other personal information outside of these categories through instances where you interact with us in person, online, or by phone or mail in the context of: Receiving help
        through our customer support channels; Participation in customer surveys or contests; and Facilitation in the delivery of our Services and to respond to your inquiries. How do we use and share
        your personal information? More information about our data collection and sharing practices can be found in this privacy notice . You may contact us or by referring to the contact details at
        the bottom of this document. If you are using an authorized agent to exercise your right to opt out we may deny a request if the authorized agent does not submit proof that they have been
        validly authorized to act on your behalf.
        <strong>Will your information be shared with anyone else?</strong>
        <p>
          We may disclose your personal information with our service providers pursuant to a written contract between us and each service provider. Each service provider is a for-profit entity that
          processes the information on our behalf, following the same strict privacy protection obligations mandated by the CCPA. We may use your personal information for our own business purposes,
          such as for undertaking internal research for technological development and demonstration. This is not considered to be "selling" of your personal information. We have not disclosed, sold,
          or shared any personal information to third parties for a business or commercial purpose in the preceding twelve (12) months. We will not sell or share personal information in the future
          belonging to website visitors, users, and other consumers.
        </p>
        <strong>Your rights with respect to your personal data</strong>
        Right to request deletion of the data — Request to delete You can ask for the deletion of your personal information. If you ask us to delete your personal information, we will respect your
        request and delete your personal information, subject to certain exceptions provided by law, such as (but not limited to) the exercise by another consumer of his or her right to free speech,
        our compliance requirements resulting from a legal obligation, or any processing that may be required to protect against illegal activities. Right to be informed — Request to know Depending on
        the circumstances, you have a right to know: whether we collect and use your personal information; the categories of personal information that we collect; the purposes for which the collected
        personal information is used; whether we sell or share personal information to third parties; the categories of personal information that we sold, shared, or disclosed for a business purpose;
        the categories of third parties to whom the personal information was sold, shared, or disclosed for a business purpose; the business or commercial purpose for collecting, selling, or sharing
        personal information; and the specific pieces of personal information we collected about you. In accordance with applicable law, we are not obligated to provide or delete consumer information
        that is de-identified in response to a consumer request or to re-identify individual data to verify a consumer request. Right to Non-Discrimination for the Exercise of a Consumer’s Privacy
        Rights We will not discriminate against you if you exercise your privacy rights. Right to Limit Use and Disclosure of Sensitive Personal Information We do not process consumer's sensitive
        personal information. Verification process Upon receiving your request, we will need to verify your identity to determine you are the same person about whom we have the information in our
        system. These verification efforts require us to ask you to provide information so that we can match it with information you have previously provided us. For instance, depending on the type of
        request you submit, we may ask you to provide certain information so that we can match the information you provide with the information we already have on file, or we may contact you through a
        communication method (e.g. , phone or email) that you have previously provided to us. We may also use other verification methods as the circumstances dictate. We will only use personal
        information provided in your request to verify your identity or authority to make the request. To the extent possible, we will avoid requesting additional information from you for the purposes
        of verification. However, if we cannot verify your identity from the information already maintained by us, we may request that you provide additional information for the purposes of verifying
        your identity and for security or fraud-prevention purposes. We will delete such additionally provided information as soon as we finish verifying you. Other privacy rights You may object to
        the processing of your personal information. You may request correction of your personal data if it is incorrect or no longer relevant, or ask to restrict the processing of the information.
        You can designate an authorized agent to make a request under the CCPA on your behalf. We may deny a request from an authorized agent that does not submit proof that they have been validly
        authorized to act on your behalf in accordance with the CCPA. You may request to opt out from future selling or sharing of your personal information to third parties. Upon receiving an opt-out
        request, we will act upon the request as soon as feasibly possible, but no later than fifteen (15) days from the date of the request submission. To exercise these rights, you can contact us or
        by referring to the contact details at the bottom of this document. If you have a complaint about how we handle your data, we would like to hear from you
      </p>
      <h3>10. DO VIRGINIA RESIDENTS HAVE SPECIFIC PRIVACY RIGHTS?</h3>
      <p>
        <strong></strong>
      </p>
      <p>
        In Short: Yes, if you are a resident of Virginia, you may be granted specific rights regarding access to and use of your personal information. Virginia CDPA Privacy Notice Under the Virginia
        Consumer Data Protection Act (CDPA): "Consumer" means a natural person who is a resident of the Commonwealth acting only in an individual or household context. It does not include a natural
        person acting in a commercial or employment context. "Personal data" means any information that is linked or reasonably linkable to an identified or identifiable natural person. "Personal
        data" does not include de-identified data or publicly available information. "Sale of personal data" means the exchange of personal data for monetary consideration. If this definition
        "consumer" applies to you, we must adhere to certain rights and obligations regarding your personal data. The information we collect, use, and disclose about you will vary depending on how you
        interact with us and our Services. To find out more, please visit the following links: Personal data we collect How we use your personal data When and with whom we share your personal data
        Your rights with respect to your personal data Right to be informed whether or not we are processing your personal data Right to access your personal data Right to correct inaccuracies in your
        personal data Right to request deletion of your personal data Right to obtain a copy of the personal data you previously shared with us Right to opt out of the processing of your personal data
        if it is used for targeted advertising, the sale of personal data, or profiling in furtherance of decisions that produce legal or similarly significant effects ( "profiling" ) We have not sold
        any personal data to third parties for business or commercial purposes. We will not sell personal data in the future belonging to website visitors, users, and other consumers. Exercise your
        rights provided under the Virginia CDPA More information about our data collection and sharing practices can be found in this privacy notice . You may contact us by email at
        support@omninexus.co , by submitting a data subject access request , or by referring to the contact details at the bottom of this document. If you are using an authorized agent to exercise
        your rights, we may deny a request if the authorized agent does not submit proof that they have been validly authorized to act on your behalf. Verification process We may request that you
        provide additional information reasonably necessary to verify you and your consumer's request. If you submit the request through an authorized agent, we may need to collect additional
        information to verify your identity before processing your request. Upon receiving your request, we will respond without undue delay, but in all cases, within forty-five (45) days of receipt.
        The response period may be extended once by forty-five (45) additional days when reasonably necessary. We will inform you of any such extension within the initial 45-day response period,
        together with the reason for the extension. Right to appeal If we decline to take action regarding your request, we will inform you of our decision and reasoning behind it. If you wish to
        appeal our decision, please email us at support@omninexus.co. Within sixty (60) days of receipt of an appeal, we will inform you in writing of any action taken or not taken in response to the
        appeal, including a written explanation of the reasons for the decisions. If your appeal if denied, you may contact the Attorney General to submit a complaint .
      </p>
      <h3>11. DO WE MAKE UPDATES TO THIS NOTICE?</h3>
      <p>
        In Short: Yes, we will update this notice as necessary to stay compliant with relevant laws. We may update this privacy notice from time to time. The updated version will be indicated by an
        updated "Revised" date and the updated version will be effective as soon as it is accessible. If we make material changes to this privacy notice, we may notify you either by prominently
        posting a notice of such changes or by directly sending you a notification. We encourage you to review this privacy notice frequently to be informed of how we are protecting your information.
        <h3>12. HOW CAN YOU CONTACT US ABOUT THIS NOTICE?</h3>
        <p>If you have questions or comments about this notice, you may email us at support@omninexus.co or contact us by post at: 8206 LOUISIANA BLVD NE, STE A, ABQ, NM 87113</p>
        <h3>13. HOW CAN YOU REVIEW, UPDATE, OR DELETE THE DATA WE COLLECT FROM YOU?</h3>
        Based on the applicable laws of your country, you may have the right to request access to the personal information we collect from you, change that information, or delete it. To request to
        review, update, or delete your personal information, please fill out and submit a data subject access request .
      </p>
    </div>
  );
};
export default Privacy;
