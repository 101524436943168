import React from "react";
import NodesAnimation from "./NodesAnimation";
import openURL from "../Hooks/openURL";
import useWindowWidth from "../Hooks/useWindowWidth";

const Landing = () => {
  const width = useWindowWidth();
  return (
    <div className="landing-container">
      <div className="landing-left">
        <div className="landing-left-content">

          {width < 800 &&
          <>
          <div className="node-sibling"></div>
          <NodesAnimation />
          </>

          }
          {/* <div className="landing-left-title">Empowering Efficiency with AI & Automation</div> */}
          <div className="landing-left-title">Crafting the Future of Business with AI</div>
          {/* <div className="landing-left-title">
                    Integrating AI and Automation Solutions into Everyday Businesses
                </div> */}
          <div className="landing-left-description">Scale & Optimize Your Business Operations Leveraging AI & Automation Solutions</div>
          <button className="demo-button" onClick={openURL}>
            Schedule a Call{" "}
          </button>
        </div>
      </div>
      <div className="landing-right">
        <NodesAnimation />
        {/* <div className="landing-image"></div> */}
      </div>
    </div>
  );
};
export default Landing;
