import React from "react";

const About = () => {
  return (
    <div className="about-container" id="about-sec">
      <h1 className="about-header">ABOUT</h1>
      <div className="about-content">
        <div className="about-body">
          At Omni Nexus, we envision a future where every business thrives through the intelligent application of AI and automation technologies. Our mission is to be the catalyst in this
          transformative era, empowering organizations to not only anticipate the demands of an ever-evolving market but to set the pace of innovation within it.
          <br />
          <br />
          We are committed to making AI accessible, allowing businesses to scale operations, optimize performance, and elevate the customer experience. By harnessing the power of
          advanced AI solutions, software automations, and real-time data integration, we help businesses unlock their full potential.
          <br />
          <br />
          We believe in the transformative power of AI to not just optimize but to redefine workflows, creating a smart business ecosystem that is agile, responsive, and ahead of the curve.
        </div>
        <div className="about-image"></div>
      </div>
    </div>
  );
};

export default About;
